import React from 'react';
import { graphql, Link } from 'gatsby';
import SEO from '../components/SEO';
import Layout from '../components/Layout';
import PersonToContact from '../components/PersonToContact';

const JobPosting = ({ data, location }) => {
  const { title } = data.markdownRemark.frontmatter;
  const { html } = data.markdownRemark;
  const { previous, next } = data;

  return (
    <Layout bodyClass="page-job-post">
      <SEO
        title={title}
        description={data.markdownRemark.excerpt}
        url={location.href}
      />
      <div className="container pb-2 pt-2">
        <img
          alt="We are hiring"
          className="img-fluid"
          src="/images/career/banner_scaled.jpg"
        />
      </div>
      <div className="container pb-2 pt-2">
        <div className="row justify-content-start">
          <div className="col-12">
            <article className="blog-post">
              <div>
                <p style={{ fontWeight: 'bolder' }}>
                  Wir glauben daran, dass Softwareentwicklung nicht kompliziert
                  sein muss. Unsere pragmatische Herangehensweise ermöglicht es
                  uns, schnell, effektiv und verlässlich produktive Lösungen zu
                  entwickeln.
                </p>
                <p>
                  pragmatic development GmbH ist ein junges, aufstrebendes
                  Unternehmen im Bereich IT-Consulting und Softwareentwicklung
                  mit Hauptsitz in Paderborn. Unsere Mitarbeiterinnen und
                  Mitarbeiter arbeiten in verteilten Teams, wahlweise aus dem
                  Homeoffice, in unserem Büro im Technologiepark in Paderborn,
                  in Co-Working-Spaces oder nach Absprache vor Ort bei unseren
                  Kunden. Mit unserer Expertise unterstützen wir namhafte
                  Großkunden und mittelständische Unternehmen bei der
                  Bereitstellung und individuellen Anpassung von CRM- und
                  ERP-Systemen aus dem Microsoft-Umfeld.
                </p>
                <p>
                  Zur Verstärkung unseres Teams suchen wir vorzugsweise in
                  Hamburg oder am Standort Paderborn zum nächstmöglichen
                  Zeitpunkt eine/n
                </p>
              </div>
              <h1 className="title">{title}</h1>

              <div
                className="content"
                dangerouslySetInnerHTML={{ __html: html }}
              />

              <br />

              <nav>
                <ul className="prevnext">
                  <li>
                    {previous && (
                      <Link to={previous.fields.slug} rel="prev">
                        {'← '}
                        {previous.frontmatter.title_short}
                      </Link>
                    )}
                  </li>
                  <li>
                    {next && (
                      <Link to={next.fields.slug} rel="next">
                        {next.frontmatter.title_short}
                        {' →'}
                      </Link>
                    )}
                  </li>
                </ul>
              </nav>
            </article>
          </div>
        </div>
      </div>
      <div className="strip">
        <div className="container pt-6 pb-6">
          <div className="col-12">
            <div className="row">
              <PersonToContact authorId="sascha-kuntze" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query (
    $id: String!
    $previousJobPostingId: String
    $nextJobPostingId: String
  ) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        title_short
        date
      }
      fields {
        slug
      }
      html
      excerpt
    }
    previous: markdownRemark(id: { eq: $previousJobPostingId }) {
      fields {
        slug
      }
      frontmatter {
        title
        title_short
      }
    }
    next: markdownRemark(id: { eq: $nextJobPostingId }) {
      fields {
        slug
      }
      frontmatter {
        title
        title_short
      }
    }
  }
`;

export default JobPosting;
